import { call, put, takeLatest, select, putResolve } from "redux-saga/effects";
import Action from "reducers/Action";
import {
  findAll,
  getColumn,
  savePickListResultApi,
  saveFocusPersoDatatable,
  deleteMany
} from "api";
import {
  INIT_ADMIN_DATATABLE,
  INIT_ADMIN_DATATABLE_SUCCESS,
  INIT_DATATABLE_MODE_ADMIN,
  ADMIN_DATATABLE_SAVE_NEW,
  ADMIN_DATATABLE_SAVE_NEW_SUCCESS,
  ADMIN_DATATABLE_SAVE_SAVE_PICKLIST,
  SAVE_ADMIN_FOCUS_DATATABLE,
  ADMIN_DATATABLE_ADD_ONE_CTRLKEY,
  SAVE_ADMIN_FILTER_BAR,
  DELETE_ADMIN_FILTER_BAR,
  ADMIN_DATATABLE_DELETE_FOCUS
} from "constant/adminDatatable";
import { AxiosResponse } from "axios";
import { PagedResource } from "types/Search";
import { Pojo } from "types/Galaxy";
import { addEntity, removeEntities, addAllEntities } from "actions";
import {
  getCtrlKeys,
  getDatatableFocusModeAdmin,
  adminDatatableSaveNew,
  saveAdminFilterBar,
  deleteDatatableFocus
} from "api/adminDatatable";
import { FocusState, DatatableFocusState } from "types/Focus";
import {
  selectCurrentOelColumns,
  selectCurrentFocus,
  isLoadedDefinition,
  selectCurrentDefinition
} from "selectors/datatable.selectors";
import {
  callApi,
  updateDatatableFocus,
  updateDatatableColumns,
  clearDatatableReduxState,
  initDatatableAdmin
} from "actions/datatable.action";
import {
  CHANGE_DATATABLE_FOCUS_SUCCESS,
  INIT_BASE_DATATABLE,
  DATATABLE_ADD_ONE_FOCUS,
  DATATABLE_UPDATE_FILTERS,
  DATATABLE_REMOVE_FILTERS,
  DATATABLE_REMOVE_FOCUS
} from "constant/datatable.constant";
import { ComponentState } from "react";
import { createTraduction } from "api/adminGommon";
import { isCtrlKeyLoaded } from "selectors/adimDatatable.selector";
import { FilterBarDefinition } from "types/Component";
import { DatatableState } from "reducers/modules/DatatableReducer";

function* initAdminDatatable(
  action: Action<{
    sjmoCode: string;
    tableName: string;
  }>
) {
  try {
    const { sjmoCode, tableName } = action.payload;

    const responseTable: AxiosResponse<PagedResource<Pojo>> = yield call(findAll, {
      sjmoCode,
      tableName: "syjTables",
      filter: "q=sjtaName==" + tableName,
      size: 1
    });

    if (responseTable.data.data.length > 0) {
      yield put(
        addEntity(
          sjmoCode,
          "syjTables",
          responseTable.data.data[0].id,
          responseTable.data.data,
          true
        )
      );

      const responseCtrlKey: AxiosResponse<FocusState[]> = yield call(
        getCtrlKeys,
        sjmoCode,
        tableName
      );

      yield put({ type: INIT_ADMIN_DATATABLE_SUCCESS, payload: responseCtrlKey.data });
    }
  } catch (error) {
    console.log("Error in adminDatatable.saga.initAdminatatable", error);
  }
}

function* watchInitDatatableModeAdmin(
  action: Action<{ sjmoCode: string; ctrlKey: string; tableName: string; focusId: string }>
): any {
  const { sjmoCode, ctrlKey, tableName, focusId } = action.payload;
  try {
    const response: AxiosResponse<FocusState[]> = yield call(
      getDatatableFocusModeAdmin,
      sjmoCode,
      tableName,
      ctrlKey
      // focusId
    );

    yield putResolve(clearDatatableReduxState(sjmoCode));
    yield putResolve(initDatatableAdmin(sjmoCode, tableName, ctrlKey));

    // on enregistre la liste des focus
    yield putResolve(updateDatatableFocus(sjmoCode, ctrlKey, response.data));

    if (response.data.length > 0) {
      const selectedFocus = focusId ? focusId : response.data[0].focusId;
      yield putResolve({
        type: CHANGE_DATATABLE_FOCUS_SUCCESS,
        payload: { sjmoCode, ctrlKey, selectedFocus }
      });

      const responseColumn: AxiosResponse<ComponentState[]> = yield call(
        getColumn,
        sjmoCode,
        selectedFocus
      );
      yield putResolve(updateDatatableColumns(sjmoCode, ctrlKey, responseColumn.data));
    }

    const oelColumns: any = yield select(selectCurrentOelColumns, { sjmoCode, ctrlKey });

    yield put(
      callApi({
        sjmoCode,
        tableName,
        ctrlKey,
        startIndex: 0,
        reset: true,
        includeOel: oelColumns,
        includeStyle: true
      })
    );
  } catch (error) {
    console.log("error in adminDatatable.saga.ts/watchInitDatatableModeAdmin", error);
  }
}

function* watchSaveNewDatatable(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    ctrlKey: string;
    title: string;
    syjModuleId?: string;
    sysMenuGroupeId?: string;
    personnelId?: string;
    privilegie: boolean;
  }>
): any {
  try {
    const response: AxiosResponse<FocusState> = yield call(adminDatatableSaveNew, action.payload);
    const { sjmoCode, ctrlKey } = action.payload;

    // Si la table est pas déjà chargé (select par sjmoCode/ctrlKey)
    // On ajoute le focus dans la liste
    // Sinon la navigation va déclencher une réinitialisation complete de la table.
    const existing = yield select(isLoadedDefinition, { sjmoCode, ctrlKey });
    if (existing) {
      yield putResolve({
        type: DATATABLE_ADD_ONE_FOCUS,
        payload: { sjmoCode, ctrlKey, focus: response.data }
      });
    }

    // Si la ctrlKey n'est pas présente dans la liste des ctrlKey disponible on l'ajoute
    const ctrlKeyExist = yield select(isCtrlKeyLoaded, ctrlKey);
    if (!ctrlKeyExist) {
      yield putResolve({
        type: ADMIN_DATATABLE_ADD_ONE_CTRLKEY,
        payload: { ctrlKey }
      });
    }

    yield putResolve({
      type: ADMIN_DATATABLE_SAVE_NEW_SUCCESS,
      payload: {
        saveState: "SUCCESS",
        focusToNavigate: response.data.focusId
      }
    });
  } catch (error) {
    console.log("error in adminDatatable.saga.ts/watchSaveNewDatatable", error);
  }
}

function* watchsavePickListResult(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    chosen: Pojo[];
    oldIdToDelete: string[];
    title: string;
    resourceKey: string;
  }>
) {
  const { sjmoCode, tableName, chosen, oldIdToDelete, resourceKey, title } = action.payload;
  try {
    const response: AxiosResponse<Pojo[]> = yield call(
      savePickListResultApi,
      sjmoCode,
      tableName,
      chosen,
      oldIdToDelete
    );

    yield call(createTraduction, { sjmoCode, resourceKey, title });

    yield putResolve(removeEntities(sjmoCode, tableName, oldIdToDelete));
    yield putResolve(addAllEntities(sjmoCode, tableName, response.data, true));
    yield putResolve({
      type: ADMIN_DATATABLE_SAVE_NEW_SUCCESS,
      payload: { saveState: "SUCCESS" }
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchSaveAdminFocusDatatable(
  action: Action<{
    datatableId: string;
    tableName: string;
    ctrlKey: string;
    sjmoCode: string;
    columns: ComponentState[];
  }>
) {
  const { sjmoCode, tableName, ctrlKey, columns, datatableId } = action.payload;
  try {
    const { breakColumns }: DatatableState = yield select(selectCurrentDefinition, {
      sjmoCode,
      ctrlKey
    });

    // on lance la sauvegarde de notre focus
    yield call(
      saveFocusPersoDatatable,
      datatableId,
      tableName,
      ctrlKey,
      sjmoCode,
      columns,
      breakColumns
    );
  } catch (e) {
    console.log(e);
  }
}

function* watchSaveAdminFilterBar(
  action: Action<{
    ctrlKey: string;
    sjmoCode: string;
    filter: Pojo;
  }>
) {
  const { sjmoCode, ctrlKey, filter } = action.payload;
  try {
    const response: AxiosResponse<Pojo> = yield call(saveAdminFilterBar, sjmoCode, filter);
    const saved = response.data;
    if (saved) {
      const savedFilter: FilterBarDefinition = {
        filterBarId: saved.id,
        privilegie: saved.sjmfPrivilegie,
        label: filter.label,
        sjmfFiltre: filter.sjmfFiltre
      };
      yield put({
        type: DATATABLE_UPDATE_FILTERS,
        payload: { sjmoCode, ctrlKey, filter: savedFilter }
      });
      yield putResolve({
        type: ADMIN_DATATABLE_SAVE_NEW_SUCCESS,
        payload: { saveState: "SUCCESS" }
      });
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchDeleteAdminFilterBar(
  action: Action<{
    ctrlKey: string;
    sjmoCode: string;
    id: string;
  }>
) {
  const { sjmoCode, ctrlKey, id } = action.payload;
  try {
    yield call(deleteMany, "syjModuleFiltre", [id], sjmoCode);

    yield put({
      type: DATATABLE_REMOVE_FILTERS,
      payload: { sjmoCode, ctrlKey, id }
    });

    yield put({
      type: ADMIN_DATATABLE_SAVE_NEW_SUCCESS,
      payload: { saveState: "SUCCESS" }
    });
  } catch (e) {
    console.log(e);
  }
}

function* watchDeleteFocus(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    ctrlKey: string;
    focusId: string;
  }>
): any {
  const { sjmoCode, ctrlKey, focusId } = action.payload;
  try {
    const response = yield call(deleteDatatableFocus, sjmoCode, focusId);

    yield putResolve({
      type: DATATABLE_REMOVE_FOCUS,
      payload: { sjmoCode, ctrlKey, focusId: response.data }
    });
    yield put({
      type: ADMIN_DATATABLE_SAVE_NEW_SUCCESS,
      payload: { saveState: "SUCCESS" }
    });
  } catch (e) {
    console.log(e);
  }
}

export default [
  takeLatest(INIT_ADMIN_DATATABLE, initAdminDatatable),
  takeLatest(INIT_DATATABLE_MODE_ADMIN, watchInitDatatableModeAdmin),
  takeLatest(ADMIN_DATATABLE_SAVE_NEW, watchSaveNewDatatable),
  takeLatest(ADMIN_DATATABLE_SAVE_SAVE_PICKLIST, watchsavePickListResult),
  takeLatest(SAVE_ADMIN_FOCUS_DATATABLE, watchSaveAdminFocusDatatable),
  takeLatest(SAVE_ADMIN_FILTER_BAR, watchSaveAdminFilterBar),
  takeLatest(DELETE_ADMIN_FILTER_BAR, watchDeleteAdminFilterBar),
  takeLatest(ADMIN_DATATABLE_DELETE_FOCUS, watchDeleteFocus)
];
