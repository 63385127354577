import React, { FC, useEffect, useState, useMemo, SyntheticEvent, CSSProperties } from "react";
import { Menu } from "composants/DropDown/Menu";
import { Fa } from "composants/Icon";
import { useSelector, useDispatch } from "react-redux";
import { ReducerState } from "reducers";
import classNames from "classnames";

import "./NotificationMenu.css";
import {
  AllNotificationAction,
  NotificationIntent,
  Notification,
  NotificationGroup
} from "types/Notification";
import { formatDateRelative } from "utils/i18n";

import {
  validateNotification,
  validateAllNotification,
  getUnreadNotification,
  getReadNotification
} from "api/notification";
import { pushNotification, fetchListNotificationSuccess } from "actions/notifications.action";
import { parse, compareDesc } from "date-fns";
import { getNavigationUrlFromMap } from "utils/navigation.utils";
import { Link, useNavigate } from "react-router-dom";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import ProcessusLink from "composants/processus/ProcessusLink";
import { useTranslation } from "react-i18next";
import countBy from "lodash-es/countBy";
import { IconName } from "@fortawesome/pro-solid-svg-icons";
import {
  ProcessusDefinitionNature,
  ProcessusAffectation,
  ProcessusDefinition
} from "types/Processus";
import { URL_ADN } from "customGlobal";
import { apiAdn } from "api/common";
import { useEvent } from "hooks/useEvent";

function selectHasUnreadNotification(state: ReducerState) {
  return state.notifications.notifications.filter(
    notif => !notif.validatedAt && notif.group === NotificationGroup.METIER
  ).length;
}

function selectNotifications(state: ReducerState) {
  return {
    notifications: state.notifications.notifications,
    adminLevel: state.userSettings.adminLevel
  };
}

function vibration(setVibrate: (val: boolean) => void) {
  setVibrate(true);
  return setTimeout(() => {
    setVibrate(false);
  }, 300);
}

function sortCreatedAtDesc(a: Notification, b: Notification) {
  const createdAtLeft = parse(a.createdAt);
  const createdAtRight = parse(b.createdAt);

  return compareDesc(createdAtLeft, createdAtRight);
}

export const NotificationMenu: FC = () => {
  const [t] = useTranslation("commun");

  const [tabsState, setTabsState] = useState<NotificationGroup>(NotificationGroup.EDITION);
  const nbUnreadNotifications = useSelector(selectHasUnreadNotification);
  const reducerSlice = useSelector(selectNotifications);

  const notifications = useMemo(() => {
    return reducerSlice.notifications.filter(el => el.group === tabsState);
  }, [reducerSlice.notifications, tabsState]);

  const countUnreadNotificationByGroup = useMemo(() => {
    return countBy(
      reducerSlice.notifications.filter(it => !it.validatedAt),
      it => it.group
    );
  }, [reducerSlice.notifications]);

  const [vibrate, setVibrate] = useState(false);

  const [tokenEventSource, setTokenEventSource] = useState<string | null>(null);

  const dispatchRedux = useDispatch();

  const hasUnreadNotification = nbUnreadNotifications > 0;

  useEffect(() => {
    if (tokenEventSource) return;
    // on récupère le token pour gérer les notifications
    apiAdn.post("/notifications/stream/token").then(res => {
      setTokenEventSource(res.data);
    });
  }, [tokenEventSource]);

  useEffect(() => {
    // on fetch au démarrage, puis toutes les 30 secondes
    function updateAllNotification() {
      Promise.all([getUnreadNotification(), getReadNotification(0, 50)]).then(([unread, read]) => {
        dispatchRedux(fetchListNotificationSuccess(unread.data.concat(read.data)));
      });
    }

    updateAllNotification();

    const interval = setInterval(async () => {
      updateAllNotification();
    }, 30 * 1000);

    return () => clearInterval(interval);
  }, [dispatchRedux]);

  const onMessage = useEvent((event: MessageEvent) => {
    const data = JSON.parse(event.data);
    console.log("push via event");
    dispatchRedux(pushNotification(data));
  });

  const onError = useEvent((event: MessageEvent) => {
    setTokenEventSource(null);
  });

  useEffect(() => {
    if (tokenEventSource == null) return;
    // ce token nous permet de gérer les droits d'accès au notif
    // temps réel
    let eventSource: EventSource = new EventSource(
      URL_ADN() + "/notifications/stream?token=" + tokenEventSource,
      {
        withCredentials: false
      }
    );

    eventSource.onmessage = onMessage;
    eventSource.onerror = onError;

    return () => {
      if (eventSource) eventSource.close();
    };
  }, [onError, onMessage, tokenEventSource]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasUnreadNotification) {
      timeout = vibration(setVibrate);
    }
    return () => {
      timeout && clearTimeout(timeout);
      setVibrate(false);
    };
  }, [hasUnreadNotification]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasUnreadNotification) {
      timeout = setTimeout(() => {
        timeout = vibration(setVibrate);
      }, 30 * 1000);
    }
    return () => {
      timeout && clearTimeout(timeout);
      setVibrate(false);
    };
  }, [hasUnreadNotification]);

  function validateAll() {
    validateAllNotification().then(res => {
      dispatchRedux(fetchListNotificationSuccess(res.data));
    });
  }

  const unreadMetierNotification =
    countUnreadNotificationByGroup[NotificationGroup.METIER] > 99
      ? "99+"
      : countUnreadNotificationByGroup[NotificationGroup.METIER];

  return (
    <Menu autoclose>
      <Menu.Button>
        <span className="fa-layers fa-fw fa-2x" title={t("commun_notification")}>
          <Fa
            icon={[hasUnreadNotification ? "fas" : "fal", "bell"]}
            className={classNames("has-text-white", vibrate && "vibrate-bell")}
          />
          {nbUnreadNotifications > 0 && (
            <span className="fa-layers-counter" style={{ background: "Tomato" }}>
              {nbUnreadNotifications > 99 ? "99+" : nbUnreadNotifications}
            </span>
          )}
        </span>
      </Menu.Button>
      <Menu.Overlay
        className="shadow-lg notification-scroll"
        style={{ maxWidth: 450, overflowY: "scroll", maxHeight: 400 }}
      >
        <Menu.Item as="a" className="has-text-link is-size-8" onClick={validateAll}>
          <span className="icon">
            <Fa icon={["fad", "check"]} />
          </span>
          <span>{t("commun_valider_tout")}</span>
        </Menu.Item>
        <div className="tabs">
          <ul>
            <Tab
              tabState={tabsState}
              currentTab={NotificationGroup.EDITION}
              setTabState={setTabsState}
              tabCount={countUnreadNotificationByGroup[NotificationGroup.EDITION]}
              iconName="print"
            >
              {t("commun_edition")}
            </Tab>

            <Tab
              tabState={tabsState}
              currentTab={NotificationGroup.METIER}
              setTabState={setTabsState}
              tabCount={countUnreadNotificationByGroup[NotificationGroup.METIER]}
              iconName="briefcase"
            >
              {t("commun_metier")}
              {unreadMetierNotification > 0 && (
                <strong className="is-size-7">&nbsp;({unreadMetierNotification})</strong>
              )}
            </Tab>

            <Tab
              tabState={tabsState}
              currentTab={NotificationGroup.INFO}
              setTabState={setTabsState}
              tabCount={countUnreadNotificationByGroup[NotificationGroup.INFO]}
              iconName="info-square"
            >
              {t("commun_info")}
            </Tab>

            <Tab
              tabState={tabsState}
              currentTab={NotificationGroup.DEFAULT}
              setTabState={setTabsState}
              tabCount={countUnreadNotificationByGroup[NotificationGroup.DEFAULT]}
              iconName="solar-system"
            >
              {t("commun_systeme")}
            </Tab>
          </ul>
        </div>
        <Menu.WhenVisible>
          {() => {
            return <MenuCards notifications={notifications} />;
          }}
        </Menu.WhenVisible>
      </Menu.Overlay>
    </Menu>
  );
};

const MenuCards: FC<{ notifications: Notification[] }> = ({ notifications }) => {
  const sortedNotification = useMemo(() => {
    const toSort = [...notifications];
    toSort.sort(sortCreatedAtDesc);
    return toSort;
  }, [notifications]);

  return (
    <>
      {sortedNotification.map(item => {
        return (
          <div
            key={item.id}
            className="px-7 py-8"
            style={{
              background: item.validatedBy == null ? background[item.intent] : undefined,
              borderBottom: "1px solid hsla(0, 0%, 92%, 1)",
              borderLeft: `3px solid ${colors[item.intent]}`
            }}
          >
            <NotificationCard
              id={item.id}
              title={item.title}
              actions={item.actions}
              isNew={item.validatedAt == null}
              createdAt={item.createdAt}
              intent={item.intent}
            >
              {item.message}
            </NotificationCard>
          </div>
        );
      })}
    </>
  );
};

const colors: Record<NotificationIntent, string> = {
  DEFAULT: "hsl(216, 15%, 52%)",
  INFO: "hsl(209, 62%, 50%)",
  DANGER: "hsl(0, 76%, 57%)",
  WARNING: "hsl(40, 67%, 51%)",
  SUCCESS: "hsl(148, 48%, 43%)"
};
const background: Record<NotificationIntent, string> = {
  DEFAULT: "hsl(216, 15%, 97%)",
  INFO: "hsl(209, 62%, 97%)",
  DANGER: "hsl(0, 76%, 97%)",
  WARNING: "hsl(40, 67%, 97%)",
  SUCCESS: "hsl(148, 48%, 97%)"
};

const Tab: FC<React.PropsWithChildren<{
  tabState: NotificationGroup;
  currentTab: NotificationGroup;
  tabCount: number;
  iconName: IconName;
  disabled?: boolean;
  setTabState(tab: NotificationGroup): void;
}>> = ({ tabState, currentTab, iconName, children, tabCount, disabled = false, setTabState }) => {
  return (
    <li className={classNames(tabState === currentTab && "is-active")}>
      <a
        className={disabled ? "has-text-grey-light" : undefined}
        onClick={() => !disabled && setTabState(currentTab)}
      >
        <span className="icon is-small">
          <span className="fa-layers fa-fw">
            <Fa icon={["fad", iconName]} />
            {tabCount > 0 && <span className="fa-layers-counter"></span>}
          </span>
        </span>
        <span>{children}</span>
      </a>
    </li>
  );
};

const NotificationCard: FC<React.PropsWithChildren<{
  id: string;
  title: string;
  actions?: AllNotificationAction[];
  isNew: boolean;
  createdAt: string;
  intent: NotificationIntent;
}>> = ({ id, title, children, actions = [], isNew, createdAt, intent }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function validateFocus(e: SyntheticEvent<HTMLDivElement>) {
    validateNotification(id).then(res => {
      const notification = res.data;
      dispatch(pushNotification(notification));
    });
  }

  // after action est appelé :
  // - lors du click sur un Link (on a pas de callback pour les navigations).
  // - après le succès d'un processus (onAfterSaveProcess).
  // Les deux signifie que l'utilisateur a bien effectué une des actions nécessaires et on peut donc
  // noter la notification comme validé
  function afterAction() {
    validateNotification(id).then(res => {
      const notification = res.data;
      dispatch(pushNotification(notification));
    });
  }

  return (
    <div
      role="button"
      onClick={validateFocus}
      style={{
        cursor: "pointer"
      }}
    >
      <header className={classNames("has-text-weight-semibold", isNew && "is-italic")}>
        {title}
      </header>
      <p
        className={classNames("has-text-weight-light is-size-8", {
          "has-text-grey": isNew,
          "has-text-grey-light": isNew,
          "is-italic": isNew
        })}
      >
        <div dangerouslySetInnerHTML={{ __html: children as string }} />
      </p>
      <div className="buttons" style={{ marginBottom: 0, marginTop: "0.25rem" }}>
        {actions.map((action, i) => {
          const classes = classNames("button is-small is-rounded ", {
            "is-link is-outlined": intent === "INFO",
            "is-success is-outlined": intent === "SUCCESS",
            "is-warning": intent === "WARNING",
            "is-danger is-outlined": intent === "DANGER"
          });
          const styles: CSSProperties = {
            borderStyle: "dashed"
          };

          if (action.type === "LINK") {
            const url = getNavigationUrlFromMap(action.params);
            return (
              <Link key={i} className={classes} to={url} onClick={afterAction} style={styles}>
                {action.label}
              </Link>
            );
          } else if (action.type === "DIRECT_LINK") {
            return (
              <Link
                key={i}
                className={classes}
                to={action.url}
                onClick={afterAction}
                style={styles}
              >
                {action.label}
              </Link>
            );
          } else if (action.type === "PROCESSUS") {
            const {
              apercu = false,
              entities,
              label,
              nature,
              processId,
              rapide = false,
              sjmoCode,
              tableName
            } = action.params;

            let type: ProcessusDefinitionNature;
            switch (nature) {
              case "EDITA":
              case "EDITS":
                type = "edition";
                break;
              case "JAVA":
              case "TRAIT":
                type = "traitement";
                break;
              case "NAEXT":
              case "NAINT":
                type = "navigation";
                break;
            }

            const definition = {
              id: id,
              type: type,
              label: label,
              affectation: ProcessusAffectation.GLOBAL,
              needEntity: true,
              apercu: type === "edition" ? apercu : undefined,
              rapide: type === "edition" ? rapide : undefined,
              forAll: type === "traitement" ? false : undefined,
              isAdvanced: type === "traitement" ? false : undefined
            } as ProcessusDefinition;

            return (
              <ProcessusProvider
                key={i}
                selected={entities}
                tableName={tableName}
                sjmoCode={sjmoCode}
                onAfterSaveProcess={afterAction}
              >
                {isNew ? (
                  <ProcessusLink className={classes} definition={definition} style={styles} />
                ) : (
                  // si l'action a été validé on désactive le bouton pour éviter de pouvoir relancer des traitements déjà effectué.
                  <button className={classes} style={styles} disabled>
                    {label}
                  </button>
                )}
              </ProcessusProvider>
            );
          } else if (action.type === "DISPATCH") {
            const { type, ...payload } = action.params;
            return (
              <button
                key={i}
                className={classes}
                style={styles}
                onClick={() => {
                  dispatch({ type, payload: { ...payload, navigate } });
                  // impossible de savoir si la notification est OK
                  // on lance la note alors comme validé.
                  // on ne désactive pas le bouton après validation justement au cas où la personne souhaite encore
                  // utiliser le bouton s'il y a eu une erreur lors du lancement du dispatch.
                  afterAction();
                }}
              >
                {action.label}
              </button>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className={classNames(isNew && "is-italic")} style={{ fontSize: 10, marginTop: 2 }}>
        {formatDateRelative(createdAt, true)}
      </div>
    </div>
  );
};
