import React, { Component } from "react";
import {
  Grid,
  GridCellProps,
  Index,
  InfiniteLoader,
  SectionRenderedParams,
  ScrollParams,
  GridCellRangeProps,
  InfiniteLoaderChildProps,
  IndexRange,
  GridCellRenderer,
  CellMeasurerCache
} from "react-virtualized";
import get from "lodash-es/get";
import isEqual from "lodash-es/isEqual";

import { LoadingPojo, Pojo } from "types/Galaxy";

import {
  GridPaddingType,
  LOCAL_STORAGE_GRID_PADDING,
  gridPadding,
  gridPaddingComponentSize
} from "./table/datatableBehavior";
import defaultCellRangeRenderer, {
  MAX_SIZE_EXPAND,
  MAX_SIZE_BREAK,
  MAX_SIZE_BREAK_BOTTOM
} from "./cellRange/cellRangeRenderer";
import Loader from "composants/Loader";

import { SortableHeaderContainer } from "./SortableElements";
import { ColumnsContext, ColumnRenderer, ColumnProps } from "./TableColumn";

import { formatDateTime, formatDate, t } from "utils/i18n";
import { getNumberFormat } from "utils/network.utils";
import ObserveParent from "./ObserveParent";

import { parse } from "date-fns";

export type TableDataType = Pojo | LoadingPojo | undefined;

type OnRowsRendered = (params: { startIndex: number; stopIndex: number }) => void;

export type BreakRow = { col: string; label: string };
export type BreakSubtotal = { id: string; label: string };
interface TableProps {
  ctrlKey?: string;
  isLoading?: boolean;
  headerClassName?: string;
  entities: Record<any, TableDataType>;
  breakRowColumn: BreakRow[];
  breakSubtotals: BreakSubtotal[];
  selectedRows: number[];
  totalRecords: number;
  toolbar?: React.ReactNode;
  showDirty: boolean;
  isFilterVisible: boolean;
  gridPadding: GridPaddingType;
  rowExpand?: Record<string, boolean>;
  rowExpandData?: Record<string, any>;
  selectedRow?: number | null;
  autoWidth: boolean;
  rowHeight?: number;
  minimumBatchSize: number;
  threshold: number;
  autoHeight?: boolean;
  onReorderColumnEnd?: (props: { oldIndex: number; newIndex: number }) => void;
  onRowClick?: (index: number) => void;
  rowExpandRenderer?(expandedProps: GridCellProps): React.ReactNode;
  loadMoreData(index: IndexRange): Promise<void>;
}

export type DatatableSort = "ASC" | "DESC" | undefined;

export const SCROLL_DIRECTION_BACKWARD = -1;
export const SCROLL_DIRECTION_FORWARD = 1;

interface OverscanIndicesGetterParams {
  // One of SCROLL_DIRECTION_HORIZONTAL or SCROLL_DIRECTION_VERTICAL
  direction: "horizontal" | "vertical";

  // One of SCROLL_DIRECTION_BACKWARD or SCROLL_DIRECTION_FORWARD
  scrollDirection: -1 | 1;

  // Number of rows or columns in the current axis
  cellCount: number;

  // Maximum number of cells to over-render in either direction
  overscanCellsCount: number;

  // Begin of range of visible cells
  startIndex: number;

  // End of range of visible cells
  stopIndex: number;
}

export interface TableState {
  scrollLeft: number;
  scrollTop: number;
}

export const CellMeasurerCacheContext = React.createContext<CellMeasurerCache | null>(null);

export default class Table extends Component<React.PropsWithChildren<TableProps>, TableState> {
  static defaultProps = {
    showDirty: true,
    isFilterVisible: false,
    selectedRows: [],
    breakRowColumn: [],
    breakSubtotals: [],
    gridPadding: (localStorage.getItem(LOCAL_STORAGE_GRID_PADDING) as GridPaddingType) || "small",
    autoWidth: false,
    minimumBatchSize: 25,
    threshold: 20
  };

  state: TableState = {
    scrollLeft: 0,
    scrollTop: 0
  };

  private infiniteLoaderRef = React.createRef<InfiniteLoader>();
  private bodyGridRef: Grid | null = null;
  private headerGridRef: Grid | null;
  private previousChildren: React.ReactNode | null = null;
  private columnsCache: ColumnProps[];

  private cache = new CellMeasurerCache({
    defaultWidth: 100,
    defaultHeight: this.gridPadding,
    fixedHeight: this.props.autoHeight !== true,
    fixedWidth: true,
    minHeight: 40
  });

  get gridPadding(): number {
    return gridPadding(this.props.gridPadding);
  }

  get gridPaddingComponentSize() {
    return gridPaddingComponentSize(this.props.gridPadding);
  }

  public recomputeHeader = (params?: { columnIndex: number; rowIndex: number }) => {
    if (this.headerGridRef) {
      this.headerGridRef.recomputeGridSize(params);
    }
  };

  public recomputeBody = (params?: { columnIndex: number; rowIndex: number }) => {
    if (this.bodyGridRef) {
      this.bodyGridRef.recomputeGridSize(params);
    }
  };

  public recomputeGrids = (params?: { columnIndex: number; rowIndex: number }) => {
    this.recomputeHeader(params);
    this.recomputeBody(params);
  };

  public scrollTop = () => {
    this.setState({
      scrollTop: 0
    });
  };

  public resetInfiniteLoader = (autoReload?: boolean) => {
    if (this.infiniteLoaderRef.current) {
      this.infiniteLoaderRef.current.resetLoadMoreRowsCache(autoReload);
    }
  };

  public reset = () => {
    this.recomputeGrids();
    this.resetInfiniteLoader();
  };

  _mapForColumnProps(compo: any): ColumnProps {
    return compo.props;
  }

  getColumns = (): ColumnProps<any>[] => {
    if (this.props.children !== this.previousChildren) {
      const children = React.Children.toArray(this.props.children);
      this.columnsCache = children.map(this._mapForColumnProps);
      this.previousChildren = this.props.children;
    }

    return this.columnsCache;
  };

  getColumnWidth = ({ index }: Index) => {
    const columns = this.getColumns();

    // on essaye d'abord de récupérer la valeur par columnWidthName
    return get(columns, [index, "width"], 120);
  };

  getRowHeight = ({ index }: Index) => {
    const isBreakRow = this.isBreakRow(index);

    let rowHeight;
    if (this.props.autoHeight) {
      rowHeight = this.cache.rowHeight({ index });
    } else if (this.props.rowHeight !== undefined) {
      rowHeight = this.props.rowHeight;
    } else {
      rowHeight = this.gridPadding;
    }

    if (isBreakRow) {
      rowHeight += MAX_SIZE_BREAK;
    }

    if (this.props.breakSubtotals.length > 0) {
      rowHeight += MAX_SIZE_BREAK_BOTTOM;
    }

    const isExpanded = this.isRowExpandable(index);
    if (isExpanded) {
      rowHeight += MAX_SIZE_EXPAND;
    }

    return rowHeight;
  };

  onSectionRendered = (
    { rowStartIndex, rowStopIndex }: SectionRenderedParams,
    onRowsRendered: OnRowsRendered
  ) => {
    const startIndex = rowStartIndex;
    const stopIndex = rowStopIndex;

    onRowsRendered({ startIndex, stopIndex });
  };

  onScrollHeader = ({ scrollLeft }: ScrollParams) => {
    this.setState({
      scrollLeft
    });
  };

  onScrollBody = ({ scrollLeft, scrollTop }: ScrollParams) => {
    this.setState({
      scrollLeft,
      scrollTop
    });
  };

  isBreakRow = (index: number) => {
    if (this.props.breakRowColumn.length <= 0) {
      return false;
    }

    if (index === 0 && this.props.breakRowColumn.length > 0) {
      return true;
    }

    const previousEntity = this.props.entities[index - 1];
    const currentEntity = this.props.entities[index];

    let currentBreak: BreakRow;
    for (let i = 0, length = this.props.breakRowColumn.length; i < length; i++) {
      currentBreak = this.props.breakRowColumn[i];
      if (
        previousEntity &&
        previousEntity !== "LOADING_POJO" &&
        currentEntity &&
        currentEntity !== "LOADING_POJO" &&
        !isEqual(previousEntity[currentBreak.col], currentEntity[currentBreak.col])
      ) {
        return true;
      }
    }

    return false;
  };

  isSubtotal = (index: number) => {
    if (this.props.breakSubtotals.length <= 0) {
      return false;
    } else if (index + 1 >= this.props.totalRecords) {
      return true;
    } else {
      return this.isBreakRow(index + 1);
    }
  };

  breakRowSubtotal = (index: number) => {
    const entity = this.props.entities[index];

    const numberFormat = getNumberFormat();
    const labels = this.props.breakSubtotals.map(subtotal => {
      const formatted =
        entity && entity !== "LOADING_POJO" ? numberFormat.format(entity[subtotal.id]) : "";
      return `${subtotal.label} : ${formatted}`;
    });

    return labels.join(",");
  };

  isRowExpandable = (index: number) => {
    const entity = this.props.entities[index];
    const id = entity !== undefined && entity !== "LOADING_POJO" ? entity.id || entity.uuid : "";
    return get(this.props.rowExpand, id, false);
  };

  getBreakLabel = (index: number) => {
    const entity = this.props.entities[index];

    return entity !== undefined && entity !== "LOADING_POJO"
      ? this.props.breakRowColumn.reduce((acc, curr) => {
          let isEmpty = acc.length === 0;
          let label: string = curr.label;

          let separator = isEmpty ? "" : ",";
          let value = entity[curr.col];

          let finalValue;

          try {
            // pas la peine de parser un nombre
            if (typeof value == "number") throw new Error("cannot cast number to date");

            // on essaye aussi avant si parser en nombre fonctionne,
            // dans ce cas là, pas la peine non plus
            if (
              typeof value == "string" &&
              (!Number.isNaN(parseInt(value)) || !Number.isNaN(parseFloat(value)))
            )
              throw new Error("cannot cast number to date. Even when serialized as a string");

            const parsed = parse(value);
            if (
              parsed.getHours() === 0 &&
              parsed.getMinutes() === 0 &&
              parsed.getMilliseconds() === 0
            ) {
              finalValue = formatDate(parsed);
            } else {
              finalValue = formatDateTime(parsed);
            }
          } catch {
            finalValue = value;
          }
          if (!finalValue) finalValue = t("commun_aucune_valeur");

          return `${acc}${separator} ${label} : ${finalValue}`;
        }, "")
      : "";
  };

  cellRangeRenderer = (props: GridCellRangeProps) => {
    return defaultCellRangeRenderer(
      props,
      this.isBreakRow,
      this.getBreakLabel,
      this.isSubtotal,
      this.breakRowSubtotal,
      this.isRowExpandable,
      this.props.rowExpandRenderer
    );
  };

  overscanIndiceGetter = ({
    scrollDirection,
    overscanCellsCount,
    startIndex,
    stopIndex,
    cellCount
  }: OverscanIndicesGetterParams) => {
    const calculatedOvercan = Math.round(overscanCellsCount / 2);
    if (scrollDirection === SCROLL_DIRECTION_FORWARD) {
      return {
        overscanStartIndex: Math.max(0, startIndex - calculatedOvercan),
        overscanStopIndex: Math.min(cellCount - 1, stopIndex + calculatedOvercan)
      };
    } else {
      return {
        overscanStartIndex: Math.max(0, startIndex - calculatedOvercan),
        overscanStopIndex: Math.min(cellCount - 1, stopIndex + calculatedOvercan)
      };
    }
  };

  render() {
    return (
      <ColumnsContext.Provider value={this.getColumns()}>
        <InfiniteLoader
          ref={this.infiniteLoaderRef}
          isRowLoaded={({ index }) => !!this.props.entities[index]}
          loadMoreRows={this.props.loadMoreData}
          rowCount={this.props.totalRecords}
          minimumBatchSize={this.props.minimumBatchSize}
          threshold={this.props.threshold}
        >
          {this.renderTable}
        </InfiniteLoader>
      </ColumnsContext.Provider>
    );
  }

  renderTable = ({ registerChild, onRowsRendered }: InfiniteLoaderChildProps) => {
    return (
      <ObserveParent>
        {({ ref, width, height }) => {
          const columns = this.getColumns();
          const columnsCount = columns.length;

          const { autoWidth } = this.props;

          let columnWidthSize = 0;
          if (autoWidth && columns.length > 0) {
            columnWidthSize = width / columns.length;
          }

          if (columnWidthSize < 200) {
            columnWidthSize = 200;
          }

          return (
            <div ref={ref}>
              <div className="datatable-head">
                {this.props.toolbar
                  ? React.cloneElement(this.props.toolbar as any, { width: width })
                  : null}
                <SortableHeaderContainer
                  // sortable parameter
                  innerRef={el => (this.headerGridRef = el)}
                  axis="x"
                  lockAxis="x"
                  distance={25}
                  onSortEnd={this.props.onReorderColumnEnd}
                  useDragHandle
                  // grid parameter
                  className={this.props.headerClassName}
                  tabIndex={-1}
                  width={width}
                  height={this.props.isFilterVisible ? 80 : 50}
                  rowHeight={this.props.isFilterVisible ? 80 : 50}
                  rowCount={1}
                  columnCount={columnsCount}
                  columnWidth={autoWidth ? columnWidthSize : this.getColumnWidth}
                  overscanColumnCount={30}
                  cellRenderer={this.renderHeaderCell}
                  onScroll={this.onScrollHeader}
                  scrollLeft={this.state.scrollLeft}
                />
              </div>
              <div>
                <CellMeasurerCacheContext.Provider
                  value={this.props.autoHeight === true ? this.cache : null}
                >
                  <Grid
                    ref={el => {
                      this.bodyGridRef = el;
                      registerChild(el);
                    }}
                    className="datatable-body"
                    tabIndex={-1}
                    // overscanIndicesGetter={this.overscanIndiceGetter as any}
                    onSectionRendered={params => this.onSectionRendered(params, onRowsRendered)}
                    width={width}
                    height={height - ((this.props.isFilterVisible ? 80 : 50) + 60)}
                    rowCount={this.props.totalRecords || Object.keys(this.props.entities).length}
                    rowHeight={this.getRowHeight}
                    deferredMeasurementCache={
                      this.props.autoHeight === true ? this.cache : undefined
                    }
                    columnCount={columnsCount}
                    columnWidth={autoWidth ? columnWidthSize : this.getColumnWidth}
                    // overscanRowCount={16}
                    // overscanColumnCount={20}
                    noContentRenderer={this.renderEmpty}
                    cellRenderer={this.renderBodyCell}
                    cellRangeRenderer={this.cellRangeRenderer}
                    onScroll={this.onScrollBody}
                    scrollLeft={this.state.scrollLeft}
                    scrollTop={this.state.scrollTop}
                  />
                </CellMeasurerCacheContext.Provider>
              </div>
            </div>
          );
        }}
      </ObserveParent>
    );
  };

  renderBodyCell: GridCellRenderer = ({ key, ...props }) => {
    const isRowSelected =
      this.props.selectedRows && this.props.selectedRows.indexOf(props.rowIndex) !== -1;

    const isFirstRow =
      this.props.selectedRows && this.props.selectedRows.indexOf(props.rowIndex - 1) === -1;

    const isRowNextSelected =
      this.props.selectedRows && this.props.selectedRows.indexOf(props.rowIndex + 1) !== -1;

    const highlightRow =
      this.props.selectedRow === props.rowIndex ? "background-highlight-row" : undefined;

    return (
      <ColumnRenderer
        key={key}
        className={highlightRow}
        {...props}
        data={this.props.entities[props.rowIndex]}
        isRowSelected={isRowSelected}
        isFirstRow={isFirstRow}
        isRowNextSelected={isRowNextSelected}
        showDirty={this.props.showDirty}
        componentSize={this.gridPaddingComponentSize}
        onRowClick={this.props.onRowClick}
      />
    );
  };

  renderHeaderCell = ({ columnIndex, key, style, parent }: GridCellProps) => {
    const columns = this.getColumns();
    const columnProps = columns[columnIndex];

    return columnProps.renderHeaderCell({
      key,
      className: "datatable-header-cell",
      style,
      columnIndex,
      parent
    });
  };

  renderEmpty = () => {
    if (this.props.isLoading) {
      return (
        <div className="flex justify-center pt-5">
          <div>
            <Loader />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };
}
