import { Pojo } from "./Galaxy";
import { ComponentState } from "types/Component";
import { ComponentGroupState } from "types/ComponentGroup";
import { NavigationDefinition } from "constant/navigation";
import { ProcessusComponentGroupState } from "reducers/modules/processus/ProcessusReducer";
import { Notification, NotificationIntent } from "./Notification";

export type ProcessusNatureValue = "NAINT" | "NAEXT" | "TRAIT" | "JAVA" | "EDITS" | "EDITA";

export enum ProcessusAffectation {
  GLOBAL,
  ROLE,
  USER,
  MODULE,
  NONE
}

export type ProcessusDefinitionNature = "traitement" | "edition" | "navigation";
export enum ProcessusDefinitionNatureValue {
  TRAITEMENT = "traitement",
  EDITION = "edition",
  NAVIGATION = "navigation"
}

interface ProcessusDefinitionShared {
  id: string;
  label: string;
  affectation: ProcessusAffectation;
  needEntity: boolean;
}

export interface ProcessusDefinitionTraitement extends ProcessusDefinitionShared {
  type: "traitement";
  isAdvanced: boolean;
  forAll: boolean;
  isDifferable: boolean;
}

export interface ProcessusDefinitionEdition extends ProcessusDefinitionShared {
  type: "edition";
  isAdvanced: boolean;
  apercu: boolean;
  rapide: boolean;
  isDifferable: boolean;
}

export interface ProcessusDefinitionNavigation extends ProcessusDefinitionShared {
  type: "navigation";
}

export type ProcessusDefinition =
  | ProcessusDefinitionTraitement
  | ProcessusDefinitionEdition
  | ProcessusDefinitionNavigation;

export enum ProcessusJobStatus {
  DONE = "DONE",
  SCHEDULED = "DONE",
  READY = "READY",
  RUNNING = "RUNNING"
}

export interface ProcessusAdvancedFormInit {
  definitions: ProcessusComponentGroupState[][];
  forAll: boolean;
  data: ProcessusAdvancedFormInitData;
}

export interface ProcessusAdvancedFormInitData {
  id: string;
  entities: Record<string, any>[];
  // on a un double tableau parce qu'on marche par index de step
  paramsAdd: Record<string, any>[];
}

export interface ProcessusResult {
  status: NotificationIntent;
  nav?: ActionOut;
  notification?: Notification;
}

interface ActionOut {
  type: ActionOutNavType;
  url: string;
}

type ActionOutNavType = "INT" | "EXT";

/**
 * Interface récupéré par service
 * définissant un processus de traitement ou de navigation
 */
export interface ProcessusState {
  id: string;
  label: string;
  affectation: string;
  nature: ProcessusNatureValue;
  cible: string;
  apercu: boolean;
  rapide: boolean;
  forAll: boolean;

  tableName: string;
  ids: string[];
  url?: string;

  // Cas des processus sur liste générique uniquement
  returnMsg: string;
  returnCode: number;

  entities: Partial<Pojo>[];
  paramsAdd: Partial<Pojo>[];
  definition: ComponentState[];
  navigation?: NavigationDefinition;
}

export interface ProcessusReturnStruct {
  url?: string;
  returnCode: number;
  returnMsg: string;
  entities: Partial<Pojo>[];
  paramsAdd: Partial<Pojo>[];
  definition: ComponentState[];
  navigation?: NavigationDefinition;
  forAll: boolean;
}

export interface EditionImprState {
  filePath: string;
  mimeType: string;
}

export interface EmailState {
  selectedEmailTo: EmailDestinataireState[];
  selectedEmailCc: EmailDestinataireState[];
  selectedEmailBcc: EmailDestinataireState[];
  sujet: string;
  corps: string;
  // l'id de l'entité principale attaché au mail
  entityId: string;
  // le nom de l'entité principale attachée au mail
  tableName: string;
  // ce sont les pièces jointes ajoutées par le gestionnaire d'édition
  piecesJointes: Record<string, string>;
  // ce sont des pièces jointes ajoutées par l'utilisateur et qui peuvent etre supprimées sur le serveur
  additionnalAttachment: Record<string, { name: string; fromGED: boolean }>;
}

export interface EmailDestinataireState {
  label: string;
  value: string;
}

export interface EmailTemplateState {
  label: string;
  syjModuleId: string;
  syjMailId: string;
}

export interface EditionState {
  editionImprDtos: EditionImprState[];
  mailStruct: EmailState[];
}
